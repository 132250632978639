import { motion } from 'framer-motion';
import { ArrowDownUp, AlertCircle } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useAccount } from 'wagmi';
import { WalletConnect } from './WalletConnect';
import { toast } from 'react-hot-toast';

export function TokenSwap() {
  const { isConnected } = useAccount();
  const [amount, setAmount] = useState('');
  const [swapType, setSwapType] = useState<'buy' | 'sell'>('buy');
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => {
      setIsOnline(false);
      toast.error('Network connection lost');
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <div className="max-w-2xl mx-auto">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="text-center mb-12"
      >
        <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
          Token Swap
        </h2>
        <p className="text-white/70">
          Trade tokens with the best rates
        </p>
      </motion.div>

      {!isOnline && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="mb-6 p-4 bg-red-500/10 border border-red-500/20 rounded-xl flex items-center gap-2 text-red-400"
        >
          <AlertCircle size={20} />
          <span>No internet connection. Please check your network.</span>
        </motion.div>
      )}

      {!isConnected ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-center glow-card rounded-2xl p-8"
        >
          <p className="text-white/70 mb-4">Connect your wallet to trade</p>
          <WalletConnect />
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="glow-card p-6 rounded-2xl"
        >
          <div className="flex justify-between mb-4">
            <button
              onClick={() => setSwapType('buy')}
              className={`px-4 py-2 rounded-xl transition-colors ${
                swapType === 'buy'
                  ? 'bg-purple-500/20 text-purple-300'
                  : 'text-white/70 hover:text-white'
              }`}
            >
              Buy
            </button>
            <button
              onClick={() => setSwapType('sell')}
              className={`px-4 py-2 rounded-xl transition-colors ${
                swapType === 'sell'
                  ? 'bg-purple-500/20 text-purple-300'
                  : 'text-white/70 hover:text-white'
              }`}
            >
              Sell
            </button>
          </div>

          <div className="space-y-4">
            <div>
              <label className="block text-sm text-white/70 mb-2">
                {swapType === 'buy' ? 'ETH Amount' : 'ZPT Amount'}
              </label>
              <input
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className="w-full bg-black/50 border border-purple-500/20 rounded-xl px-4 py-3 text-white focus:border-purple-500/50 transition-colors"
                placeholder="0.0"
              />
            </div>

            <div className="flex justify-center">
              <ArrowDownUp className="text-purple-400" />
            </div>

            <div>
              <label className="block text-sm text-white/70 mb-2">
                {swapType === 'buy' ? 'ZPT Amount' : 'ETH Amount'}
              </label>
              <input
                type="number"
                value={(Number(amount) * (swapType === 'buy' ? 1000 : 0.001)).toFixed(6)}
                readOnly
                className="w-full bg-black/50 border border-purple-500/20 rounded-xl px-4 py-3 text-white"
                placeholder="0.0"
              />
            </div>

            <button
              className="w-full bg-gradient-to-r from-purple-600 to-pink-600 text-white py-3 rounded-xl font-semibold hover:opacity-90 transition-opacity"
            >
              {swapType === 'buy' ? 'Buy ZPT' : 'Sell ZPT'}
            </button>
          </div>
        </motion.div>
      )}
    </div>
  );
}