import { useState, useEffect } from 'react';

interface ChartData {
  time: string;
  value: number;
}

export function useChartData(initialPrice: number = 0.015) {
  const [priceData, setPriceData] = useState<ChartData[]>([]);
  const [volumeData, setVolumeData] = useState<ChartData[]>([]);

  useEffect(() => {
    // Generate initial historical data
    const now = new Date();
    const historicalData: ChartData[] = [];
    const historicalVolume: ChartData[] = [];
    
    for (let i = 30; i >= 0; i--) {
      const time = new Date(now.getTime() - i * 24 * 60 * 60 * 1000)
        .toISOString()
        .split('T')[0];
      
      const randomChange = (Math.random() - 0.5) * 0.001;
      const price = initialPrice + randomChange * i;
      
      historicalData.push({
        time,
        value: price,
      });

      historicalVolume.push({
        time,
        value: Math.random() * 1000000,
      });
    }

    // Set data immediately instead of using timeouts
    setPriceData(historicalData);
    setVolumeData(historicalVolume);
  }, [initialPrice]);

  return { priceData, volumeData };
}