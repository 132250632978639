import { motion } from 'framer-motion';
import { Code2, Shield, Zap, FileCheck, Terminal, Eye, Play, AlertCircle } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

export function SmartContractsPage() {
  const [activeTab, setActiveTab] = useState('editor');
  const [contractCode, setContractCode] = useState(`// SPDX-License-Identifier: MIT
pragma solidity ^0.8.0;

contract SimpleToken {
    string public name;
    string public symbol;
    uint8 public decimals;
    uint256 public totalSupply;
    
    mapping(address => uint256) public balanceOf;
    
    constructor(
        string memory _name,
        string memory _symbol,
        uint8 _decimals,
        uint256 _totalSupply
    ) {
        name = _name;
        symbol = _symbol;
        decimals = _decimals;
        totalSupply = _totalSupply;
        balanceOf[msg.sender] = _totalSupply;
    }
}`);

  const handleCompile = () => {
    toast.success('Contract compiled successfully');
  };

  const handleDeploy = () => {
    toast.success('Contract deployed to testnet');
  };

  const handleVerify = () => {
    toast.success('Contract verified on explorer');
  };

  return (
    <div className="pt-24 min-h-screen px-6">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
            Smart Contract Development
          </h1>
          <p className="text-white/70 max-w-2xl mx-auto">
            Create, test, and deploy smart contracts with our advanced tools
          </p>
        </motion.div>

        {/* Tab Navigation */}
        <div className="flex flex-wrap justify-center gap-4 mb-8">
          {[
            { id: 'editor', label: 'Contract Editor', icon: Code2 },
            { id: 'viewer', label: 'Contract Viewer', icon: Eye },
            { id: 'deploy', label: 'Deployment', icon: Play }
          ].map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`px-6 py-3 rounded-xl transition-all duration-300 flex items-center gap-2 ${
                activeTab === tab.id
                  ? 'bg-purple-500/20 text-purple-300 shadow-lg shadow-purple-500/20'
                  : 'text-white/70 hover:text-white hover:bg-white/10'
              }`}
            >
              <tab.icon size={20} />
              {tab.label}
            </button>
          ))}
        </div>

        {/* Editor View */}
        {activeTab === 'editor' && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="md:col-span-2">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="glow-card rounded-2xl overflow-hidden"
              >
                <div className="bg-black/40 p-4 flex justify-between items-center">
                  <h3 className="font-semibold">Contract Editor</h3>
                  <div className="flex gap-2">
                    <button
                      onClick={handleCompile}
                      className="px-4 py-2 bg-purple-500/20 rounded-lg text-purple-300 hover:bg-purple-500/30 transition-colors flex items-center gap-2"
                    >
                      <Terminal size={16} />
                      Compile
                    </button>
                    <button
                      onClick={handleDeploy}
                      className="px-4 py-2 bg-purple-500/20 rounded-lg text-purple-300 hover:bg-purple-500/30 transition-colors flex items-center gap-2"
                    >
                      <Play size={16} />
                      Deploy
                    </button>
                  </div>
                </div>
                <textarea
                  value={contractCode}
                  onChange={(e) => setContractCode(e.target.value)}
                  className="w-full h-[600px] bg-black/20 p-4 font-mono text-sm text-white/90 focus:outline-none resize-none"
                />
              </motion.div>
            </div>

            <div>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="glow-card p-6 rounded-2xl"
              >
                <h3 className="text-xl font-semibold mb-4">Contract Info</h3>
                <div className="space-y-4">
                  <div className="p-4 bg-black/20 rounded-xl">
                    <p className="text-white/60 text-sm">Solidity Version</p>
                    <p className="font-semibold">^0.8.0</p>
                  </div>
                  <div className="p-4 bg-black/20 rounded-xl">
                    <p className="text-white/60 text-sm">License</p>
                    <p className="font-semibold">MIT</p>
                  </div>
                  <div className="p-4 bg-black/20 rounded-xl">
                    <p className="text-white/60 text-sm">Status</p>
                    <p className="text-green-400 font-semibold">Compiled</p>
                  </div>
                </div>

                <div className="mt-8">
                  <h4 className="font-semibold mb-4">Security Checks</h4>
                  <div className="space-y-2">
                    {[
                      { check: 'Reentrancy', status: 'pass' },
                      { check: 'Overflow', status: 'pass' },
                      { check: 'Access Control', status: 'warning' }
                    ].map((item, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between p-3 bg-black/20 rounded-lg"
                      >
                        <span>{item.check}</span>
                        <span className={item.status === 'pass' ? 'text-green-400' : 'text-yellow-400'}>
                          {item.status === 'pass' ? '✓' : '⚠️'}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        )}

        {/* Contract Viewer */}
        {activeTab === 'viewer' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="grid grid-cols-1 md:grid-cols-2 gap-8"
          >
            <div className="glow-card p-6 rounded-2xl">
              <h3 className="text-xl font-semibold mb-4">Deployed Contracts</h3>
              <div className="space-y-4">
                {[
                  {
                    name: 'SimpleToken',
                    address: '0x1234...5678',
                    network: 'Testnet',
                    status: 'Verified'
                  },
                  {
                    name: 'NFTMarket',
                    address: '0x8765...4321',
                    network: 'Mainnet',
                    status: 'Verified'
                  }
                ].map((contract, index) => (
                  <div key={index} className="p-4 bg-black/20 rounded-xl">
                    <div className="flex justify-between items-center mb-2">
                      <span className="font-semibold">{contract.name}</span>
                      <span className="text-green-400 text-sm">{contract.status}</span>
                    </div>
                    <p className="text-sm text-white/60">{contract.address}</p>
                    <p className="text-sm text-white/60">{contract.network}</p>
                    <button
                      onClick={handleVerify}
                      className="mt-2 px-4 py-2 bg-purple-500/20 rounded-lg text-purple-300 hover:bg-purple-500/30 transition-colors text-sm"
                    >
                      View on Explorer
                    </button>
                  </div>
                ))}
              </div>
            </div>

            <div className="glow-card p-6 rounded-2xl">
              <h3 className="text-xl font-semibold mb-4">Contract Analytics</h3>
              <div className="space-y-6">
                <div className="grid grid-cols-2 gap-4">
                  <div className="p-4 bg-black/20 rounded-xl">
                    <p className="text-white/60 text-sm">Total Transactions</p>
                    <p className="text-xl font-semibold">1,234</p>
                  </div>
                  <div className="p-4 bg-black/20 rounded-xl">
                    <p className="text-white/60 text-sm">Unique Users</p>
                    <p className="text-xl font-semibold">567</p>
                  </div>
                </div>
                <div className="p-4 bg-black/20 rounded-xl">
                  <h4 className="font-semibold mb-2">Gas Usage</h4>
                  <div className="h-32 bg-black/40 rounded-lg"></div>
                </div>
              </div>
            </div>
          </motion.div>
        )}

        {/* Deployment */}
        {activeTab === 'deploy' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="grid grid-cols-1 md:grid-cols-2 gap-8">          <div className="glow-card p-6 rounded-2xl">
              <h3 className="text-xl font-semibold mb-4">Deploy Contract</h3>
              <div className="space-y-4">
                <div className="p-4 bg-black/20 rounded-xl">
                  <h4 className="font-semibold mb-2">Network Selection</h4>
                  <select className="w-full bg-black/40 border border-purple-500/20 rounded-lg p-2 text-white">
                    <option value="testnet">Sepolia Testnet</option>
                    <option value="mainnet">Ethereum Mainnet</option>
                  </select>
                </div>

                <div className="p-4 bg-black/20 rounded-xl">
                  <h4 className="font-semibold mb-2">Constructor Arguments</h4>
                  <div className="space-y-2">
                    <input
                      type="text"
                      placeholder="Token Name"
                      className="w-full bg-black/40 border border-purple-500/20 rounded-lg p-2 text-white"
                    />
                    <input
                      type="text"
                      placeholder="Token Symbol"
                      className="w-full bg-black/40 border border-purple-500/20 rounded-lg p-2 text-white"
                    />
                    <input
                      type="number"
                      placeholder="Decimals"
                      className="w-full bg-black/40 border border-purple-500/20 rounded-lg p-2 text-white"
                    />
                    <input
                      type="number"
                      placeholder="Total Supply"
                      className="w-full bg-black/40 border border-purple-500/20 rounded-lg p-2 text-white"
                    />
                  </div>
                </div>

                <button
                  onClick={handleDeploy}
                  className="w-full py-3 bg-gradient-to-r from-purple-600 to-pink-600 rounded-xl text-white font-semibold hover:opacity-90 transition-opacity"
                >
                  Deploy Contract
                </button>
              </div>
            </div>

            <div className="glow-card p-6 rounded-2xl">
              <h3 className="text-xl font-semibold mb-4">Deployment History</h3>
              <div className="space-y-4">
                {[
                  {
                    contract: 'SimpleToken',
                    timestamp: '2024-03-20 14:30',
                    status: 'Success',
                    txHash: '0x1234...5678'
                  },
                  {
                    contract: 'NFTMarket',
                    timestamp: '2024-03-19 09:15',
                    status: 'Success',
                    txHash: '0x8765...4321'
                  }
                ].map((deployment, index) => (
                  <div key={index} className="p-4 bg-black/20 rounded-xl">
                    <div className="flex justify-between items-center mb-2">
                      <span className="font-semibold">{deployment.contract}</span>
                      <span className="text-green-400 text-sm">{deployment.status}</span>
                    </div>
                    <p className="text-sm text-white/60">{deployment.timestamp}</p>
                    <p className="text-sm text-white/60">Tx: {deployment.txHash}</p>
                  </div>
                ))}
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
}