import { motion } from 'framer-motion';
import { BarChart3, TrendingUp, DollarSign, Activity, Clock, Shield } from 'lucide-react';
import { useAccount } from 'wagmi';
import { useState } from 'react';

export function TokenStats() {
  const { address, isConnected } = useAccount();
  const [marketStats] = useState({
    price: 0.015,
    volume24h: '$2.5M',
    marketCap: '$15M',
    holders: '12,345'
  });

  return (
    <div className="min-h-screen py-20 px-6">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-4">Market Statistics</h2>
          <p className="text-white/70 max-w-2xl mx-auto">
            Real-time market data and analytics
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            whileHover={{ scale: 1.02 }}
            className="bg-white/5 backdrop-blur-md p-6 rounded-2xl border border-white/10"
          >
            <BarChart3 className="w-8 h-8 mb-4 text-white/80" />
            <h3 className="text-xl font-semibold mb-2">Price</h3>
            <p className="text-3xl font-bold">
              ${marketStats.price.toFixed(4)}
            </p>
            <div className="mt-4 text-sm text-green-400 flex items-center gap-2">
              <TrendingUp size={16} />
              <span>+15.3%</span>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            whileHover={{ scale: 1.02 }}
            transition={{ delay: 0.1 }}
            className="bg-white/5 backdrop-blur-md p-6 rounded-2xl border border-white/10"
          >
            <Activity className="w-8 h-8 mb-4 text-white/80" />
            <h3 className="text-xl font-semibold mb-2">24h Volume</h3>
            <p className="text-3xl font-bold">{marketStats.volume24h}</p>
            <div className="mt-4 text-sm text-white/60 flex items-center gap-2">
              <Clock size={16} />
              <span>Updated 5m ago</span>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            whileHover={{ scale: 1.02 }}
            transition={{ delay: 0.2 }}
            className="bg-white/5 backdrop-blur-md p-6 rounded-2xl border border-white/10"
          >
            <DollarSign className="w-8 h-8 mb-4 text-white/80" />
            <h3 className="text-xl font-semibold mb-2">Market Cap</h3>
            <p className="text-3xl font-bold">{marketStats.marketCap}</p>
            <div className="mt-4 text-sm text-white/60 flex items-center gap-2">
              <Shield size={16} />
              <span>{marketStats.holders} Holders</span>
            </div>
          </motion.div>
        </div>

        {isConnected && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="bg-white/5 backdrop-blur-md p-6 rounded-2xl border border-white/10 mb-12"
          >
            <h3 className="text-xl font-semibold mb-4">Your Balance</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-white/60">ETH Balance</p>
                <p className="text-2xl font-semibold">Loading...</p>
              </div>
              <div>
                <p className="text-white/60">ZPT Balance</p>
                <p className="text-2xl font-semibold">Loading...</p>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
}