import { motion } from 'framer-motion';
import { Search, BarChart2, Clock, Layers, ArrowRight, ExternalLink } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

export function ExplorerPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('transactions');

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (!searchQuery) {
      toast.error('Please enter a search query');
      return;
    }
    toast.success('Searching blockchain...');
  };

  return (
    <div className="pt-24 min-h-screen px-6">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
            Blockchain Explorer
          </h1>
          <p className="text-white/70 max-w-2xl mx-auto">
            Explore and analyze blockchain data in real-time
          </p>
        </motion.div>

        {/* Search Bar */}
        <div className="max-w-3xl mx-auto mb-12">
          <form onSubmit={handleSearch} className="relative">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search by address, transaction hash, or block number..."
              className="w-full bg-black/20 border border-purple-500/20 rounded-xl pl-12 pr-4 py-3 text-white placeholder-white/40"
            />
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white/40" />
            <button
              type="submit"
              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-purple-400 hover:text-purple-300"
            >
              <ArrowRight />
            </button>
          </form>
        </div>

        {/* Tab Navigation */}
        <div className="flex flex-wrap justify-center gap-4 mb-8">
          {[
            { id: 'transactions', label: 'Transactions', icon: Clock },
            { id: 'blocks', label: 'Blocks', icon: Layers },
            { id: 'analytics', label: 'Analytics', icon: BarChart2 }
          ].map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`px-6 py-3 rounded-xl transition-all duration-300 flex items-center gap-2 ${
                activeTab === tab.id
                  ? 'bg-purple-500/20 text-purple-300 shadow-lg shadow-purple-500/20'
                  : 'text-white/70 hover:text-white hover:bg-white/10'
              }`}
            >
              <tab.icon size={20} />
              {tab.label}
            </button>
          ))}
        </div>

        {/* Transactions View */}
        {activeTab === 'transactions' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="space-y-6"
          >
            {[...Array(5)].map((_, index) => (
              <div key={index} className="glow-card p-6 rounded-2xl">
                <div className="flex flex-wrap justify-between items-center gap-4">
                  <div>
                    <p className="text-sm text-white/60">Transaction Hash</p>
                    <p className="font-mono">0x1234...5678</p>
                  </div>
                  <div>
                    <p className="text-sm text-white/60">From</p>
                    <p className="font-mono">0xabcd...efgh</p>
                  </div>
                  <div>
                    <p className="text-sm text-white/60">To</p>
                    <p className="font-mono">0x9876...5432</p>
                  </div>
                  <div>
                    <p className="text-sm text-white/60">Value</p>
                    <p>0.5 ETH</p>
                  </div>
                  <button className="text-purple-400 hover:text-purple-300">
                    <ExternalLink size={20} />
                  </button>
                </div>
              </div>
            ))}
          </motion.div>
        )}

        {/* Blocks View */}
        {activeTab === 'blocks' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="grid grid-cols-1 md:grid-cols-2 gap-6"
          >
            {[...Array(6)].map((_, index) => (
              <div key={index} className="glow-card p-6 rounded-2xl">
                <div className="flex justify-between items-center mb-4">
                  <div>
                    <p className="text-sm text-white/60">Block Number</p>
                    <p className="font-mono text-lg">#12345678</p>
                  </div>
                  <div className="text-right">
                    <p className="text-sm text-white/60">Timestamp</p>
                    <p>2 mins ago</p>
                  </div>
                </div>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <span className="text-white/60">Transactions</span>
                    <span>150</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-white/60">Gas Used</span>
                    <span>65%</span>
                  </div>
                </div>
              </div>
            ))}
          </motion.div>
        )}

        {/* Analytics View */}
        {activeTab === 'analytics' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="grid grid-cols-1 md:grid-cols-3 gap-8"
          >
            <div className="glow-card p-6 rounded-2xl md:col-span-2">
              <h3 className="text-xl font-semibold mb-4">Network Activity</h3>
              <div className="h-64 bg-black/20 rounded-xl"></div>
            </div>
            <div className="space-y-6">
              <div className="glow-card p-6 rounded-2xl">
                <h3 className="text-xl font-semibold mb-4">Network Stats</h3>
                <div className="space-y-4">
                  <div className="flex justify-between">
                    <span className="text-white/60">TPS</span>
                    <span>25.4</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-white/60">Pending Tx</span>
                    <span>1,234</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-white/60">Gas Price</span>
                    <span>25 Gwei</span>
                  </div>
                </div>
              </div>
              <div className="glow-card p-6 rounded-2xl">
                <h3 className="text-xl font-semibold mb-4">Top Contracts</h3>
                <div className="space-y-2">
                  {[...Array(3)].map((_, index) => (
                    <div key={index} className="flex justify-between items-center p-2 bg-black/20 rounded-lg">
                      <span className="font-mono text-sm">0x1234...5678</span>
                      <span className="text-purple-400">→</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
}