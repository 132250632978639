import { motion } from 'framer-motion';
import {
  Image,
  Paintbrush,
  ShoppingBag,
  Trophy,
  Search,
  Filter,
  Grid,
  List,
  Plus,
} from 'lucide-react';
import { useState } from 'react';
import { NFTCard } from '../components/NFTCard';
import { NFTCreate } from '../components/NFTCreate';
import { toast } from 'react-hot-toast';

interface NFT {
  id: string;
  name: string;
  image: string;
  price: string;
  creator: string;
  likes: number;
}

export function NFTPage() {
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [activeCategory, setActiveCategory] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [showCreate, setShowCreate] = useState(false);

  const categories = [
    'all',
    'art',
    'collectibles',
    'music',
    'photography',
    'sports',
  ];

  const nfts: NFT[] = [
    {
      id: '1',
      name: 'Cosmic Dreamer #1',
      image: '/src/styles/assets/124x40.png',
      price: '0.5 ETH',
      creator: '0x1234...5678',
      likes: 123,
    },
    {
      id: '2',
      name: 'Digital Universe #7',
      image: '/src/styles/assets/tech.png',
      price: '1.2 ETH',
      creator: '0x8765...4321',
      likes: 89,
    },
    {
      id: '3',
      name: 'Abstract Mind #3',
      image: '/src/styles/assets/neon.png',
      price: '0.8 ETH',
      creator: '0x2468...1357',
      likes: 245,
    },
    {
      id: '4',
      name: 'Future Vision #5',
      image: '/src/styles/assets/xxxx.png',
      price: '2.0 ETH',
      creator: '0x1357...2468',
      likes: 167,
    },
  ];

  const filteredNFTs = nfts.filter((nft) =>
    nft.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleCreateNFT = () => {
    setShowCreate(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="pt-24 min-h-screen px-6">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
            NFT Marketplace
          </h1>
          <p className="text-white/70 max-w-2xl mx-auto">
            Discover, collect, and trade unique digital assets
          </p>
        </motion.div>

        {showCreate ? (
          <div className="mb-12">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-semibold">Create NFT</h2>
              <button
                onClick={() => setShowCreate(false)}
                className="text-white/60 hover:text-white"
              >
                Back to Marketplace
              </button>
            </div>
            <NFTCreate />
          </div>
        ) : (
          <>
            {/* Search and Filters */}
            <div className="mb-8">
              <div className="flex flex-wrap gap-4 items-center justify-between">
                <div className="flex-1 max-w-md">
                  <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white/40" />
                    <input
                      type="text"
                      placeholder="Search NFTs..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full bg-black/20 border border-purple-500/20 rounded-xl pl-10 pr-4 py-3 text-white placeholder-white/40 focus:border-purple-500/50 transition-colors"
                    />
                  </div>
                </div>

                <div className="flex items-center gap-4">
                  <button
                    onClick={() => setViewMode('grid')}
                    className={`p-2 rounded-lg transition-colors ${
                      viewMode === 'grid' ? 'text-purple-400' : 'text-white/40'
                    }`}
                  >
                    <Grid size={20} />
                  </button>
                  <button
                    onClick={() => setViewMode('list')}
                    className={`p-2 rounded-lg transition-colors ${
                      viewMode === 'list' ? 'text-purple-400' : 'text-white/40'
                    }`}
                  >
                    <List size={20} />
                  </button>
                </div>
              </div>

              {/* Categories */}
              <div className="flex flex-wrap gap-4 mt-4">
                {categories.map((category) => (
                  <button
                    key={category}
                    onClick={() => setActiveCategory(category)}
                    className={`px-4 py-2 rounded-xl capitalize transition-colors ${
                      activeCategory === category
                        ? 'bg-purple-500/20 text-purple-300'
                        : 'text-white/60 hover:text-white hover:bg-white/10'
                    }`}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </div>

            {/* NFT Grid/List */}
            <div
              className={`grid gap-6 ${
                viewMode === 'grid'
                  ? 'grid-cols-1 md:grid-cols-2 lg:grid-cols-4'
                  : 'grid-cols-1'
              }`}
            >
              {filteredNFTs.map((nft) => (
                <NFTCard key={nft.id} nft={nft} viewMode={viewMode} />
              ))}
            </div>
          </>
        )}

        {/* Create NFT Button */}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleCreateNFT}
          className="fixed bottom-8 right-8 px-6 py-3 bg-gradient-to-r from-purple-600 to-pink-600 rounded-full text-white font-semibold shadow-lg hover:shadow-purple-500/25 transition-all duration-300"
        >
          <div className="flex items-center gap-2">
            <Plus size={20} />
            <span>Create NFT</span>
          </div>
        </motion.button>
      </div>
    </div>
  );
}
