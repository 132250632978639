import { motion } from 'framer-motion';
import { Wallet, LogOut } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

export function WalletConnect() {
  const [address, setAddress] = useState<string | null>(null);
  const [isHovered, setIsHovered] = useState(false);

  const connectWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const accounts = await window.ethereum.request({ 
          method: 'eth_requestAccounts' 
        });
        setAddress(accounts[0]);
        toast.success('Wallet connected successfully');
      } catch (error) {
        toast.error('Failed to connect wallet');
        console.error('User rejected connection');
      }
    } else {
      window.open('https://metamask.io/download/', '_blank');
      toast.error('MetaMask not detected');
    }
  };

  const disconnectWallet = () => {
    setAddress(null);
    toast.success('Wallet disconnected');
    // Clear any stored data
    sessionStorage.clear();
    localStorage.clear();
  };

  if (address) {
    return (
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        className="relative"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <button className="bg-gradient-to-r from-purple-600/20 to-pink-600/20 backdrop-blur-md px-3 py-1.5 sm:px-6 sm:py-2 rounded-full text-white font-medium flex items-center gap-2 hover:from-purple-600/30 hover:to-pink-600/30 transition-colors text-sm sm:text-base">
          <Wallet size={16} className="sm:w-5 sm:h-5" />
          {`${address.slice(0, 6)}...${address.slice(-4)}`}
        </button>
        
        {isHovered && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="absolute top-full mt-2 right-0 bg-black/90 backdrop-blur-lg rounded-xl p-4 border border-purple-500/20 min-w-[200px]"
          >
            <button
              onClick={disconnectWallet}
              className="flex items-center gap-2 text-red-400 hover:text-red-300 transition-colors w-full"
            >
              <LogOut size={16} />
              Disconnect
            </button>
          </motion.div>
        )}
      </motion.div>
    );
  }

  return (
    <motion.button
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={connectWallet}
      className="bg-gradient-to-r from-purple-600 to-pink-600 px-3 py-1.5 sm:px-6 sm:py-2 rounded-full text-white font-medium flex items-center gap-1 sm:gap-2 hover:opacity-90 transition-opacity text-sm sm:text-base"
    >
      <Wallet size={16} className="sm:w-5 sm:h-5" />
      <span className="hidden sm:inline">Connect MetaMask</span>
      <span className="sm:hidden">Connect</span>
    </motion.button>
  );
}