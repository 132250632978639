import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X, ChevronDown } from 'lucide-react';
import { useState, useEffect } from 'react';
import { WalletConnect } from './WalletConnect';
import { Link, useLocation } from 'react-router-dom';

export function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null);
  const location = useLocation();

  const menuItems = [
    { 
      name: 'Swap', 
      href: '/' 
    },
    { 
      name: 'Features', 
      href: '/features' 
    },
    { 
      name: 'Web3 Services',
      href: '#',
      submenu: [
        { name: 'DeFi Integration', href: '/defi' },
        { name: 'NFT Marketplace', href: '/nft' },
        { name: 'Token Swaps', href: '/' },
        { name: 'Smart Contracts', href: '/smart-contracts' }
      ]
    },
    { 
      name: 'Resources',
      href: '#',
      submenu: [
        { name: 'Documentation', href: '/docs' },
        { name: 'API Reference', href: '/api' },
        { name: 'Developer Tools', href: '/dev-tools' },
        { name: 'Blockchain Explorer', href: '/explorer' },
        { name: 'Statistics', href: '/stats' }
      ]
    },
    {
      name: 'Support',
      href: '#',
      submenu: [
        { name: 'Contact Us', href: '/contact' },
        { name: 'Small Grants', href: '/grants' }
      ]
    }
  ];

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsOpen(false);
    setActiveSubmenu(null);
  }, [location]);

  const isActive = (path: string) => location.pathname === path;

  return (
    <motion.nav 
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      className="fixed w-full z-50 px-4 py-2"
    >
      <div className="max-w-7xl mx-auto">
        <motion.div
          className={`backdrop-blur-md bg-black/40 rounded-full px-4 py-2 flex items-center justify-between transition-all duration-300 ${
            scrolled ? 'shadow-lg shadow-purple-500/10' : ''
          }`}
        >
          <Link to="/">
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="text-white font-bold text-xl"
            >
              ZPecta
            </motion.div>
          </Link>

          {/* Desktop Menu */}
          <div className="hidden lg:flex items-center gap-6">
            {menuItems.map((item) => (
              <div key={item.name} className="relative group">
                {item.submenu ? (
                  <div className="relative">
                    <button
                      className={`px-3 py-1.5 rounded-full text-sm font-medium transition-colors ${
                        activeSubmenu === item.name
                          ? 'bg-white/10 text-white'
                          : 'text-white/70 hover:text-white hover:bg-white/5'
                      } flex items-center gap-1`}
                      onMouseEnter={() => setActiveSubmenu(item.name)}
                      onMouseLeave={() => setActiveSubmenu(null)}
                    >
                      {item.name}
                      <ChevronDown size={14} className="opacity-50" />
                    </button>
                    
                    <AnimatePresence>
                      {activeSubmenu === item.name && (
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 10 }}
                          className="absolute top-full left-0 mt-1 py-2 bg-black/90 backdrop-blur-lg rounded-xl border border-purple-500/20 min-w-[180px] shadow-xl shadow-purple-500/10"
                          onMouseEnter={() => setActiveSubmenu(item.name)}
                          onMouseLeave={() => setActiveSubmenu(null)}
                        >
                          {item.submenu.map((subItem) => (
                            <Link
                              key={subItem.name}
                              to={subItem.href}
                              className={`block px-4 py-1.5 text-sm ${
                                isActive(subItem.href)
                                  ? 'text-purple-400 bg-purple-500/10'
                                  : 'text-white/70 hover:text-white hover:bg-white/5'
                              } transition-colors`}
                            >
                              {subItem.name}
                            </Link>
                          ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ) : (
                  <Link
                    to={item.href}
                    className={`px-3 py-1.5 rounded-full text-sm font-medium transition-colors ${
                      isActive(item.href)
                        ? 'bg-white/10 text-white'
                        : 'text-white/70 hover:text-white hover:bg-white/5'
                    }`}
                  >
                    {item.name}
                  </Link>
                )}
              </div>
            ))}
          </div>

          <div className="flex items-center gap-4">
            <WalletConnect />
            {/* Mobile Menu Button */}
            <button 
              className="lg:hidden text-white p-1.5 hover:bg-white/10 rounded-full transition-colors"
              onClick={() => setIsOpen(!isOpen)}
            >
              {isOpen ? <X size={20} /> : <Menu size={20} />}
            </button>
          </div>
        </motion.div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              className="lg:hidden fixed top-16 left-4 right-4 max-h-[80vh] overflow-y-auto backdrop-blur-xl bg-black/95 rounded-2xl border border-purple-500/20 shadow-xl shadow-purple-500/10"
            >
              <div className="p-4 space-y-1">
                {menuItems.map((item) => (
                  <div key={item.name}>
                    {item.submenu ? (
                      <div className="mb-2">
                        <div className="px-3 py-2 text-sm font-medium text-white/90">
                          {item.name}
                        </div>
                        <div className="pl-3 space-y-1">
                          {item.submenu.map((subItem) => (
                            <Link
                              key={subItem.name}
                              to={subItem.href}
                              onClick={() => setIsOpen(false)}
                              className={`block px-3 py-2 rounded-lg text-sm ${
                                isActive(subItem.href)
                                  ? 'text-purple-400 bg-purple-500/10'
                                  : 'text-white/70 hover:text-white hover:bg-white/5'
                              } transition-colors`}
                            >
                              {subItem.name}
                            </Link>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <Link
                        to={item.href}
                        onClick={() => setIsOpen(false)}
                        className={`block px-3 py-2 rounded-lg text-sm ${
                          isActive(item.href)
                            ? 'text-white bg-white/10'
                            : 'text-white/70 hover:text-white hover:bg-white/5'
                        } transition-colors`}
                      >
                        {item.name}
                      </Link>
                    )}
                  </div>
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.nav>
  );
}