import { motion } from 'framer-motion';
import { Code, Webhook, Database, Key, Play, Terminal, Book, Copy } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

export function APIPage() {
  const [selectedEndpoint, setSelectedEndpoint] = useState('get-token');
  const [apiKey, setApiKey] = useState('');

  const endpoints = {
    'get-token': {
      method: 'GET',
      path: '/api/v1/token/{address}',
      description: 'Get token information by address',
      parameters: [
        { name: 'address', type: 'string', required: true, description: 'Token contract address' }
      ],
      response: `{
  "name": "ZPecta Token",
  "symbol": "ZPT",
  "decimals": 18,
  "totalSupply": "1000000000000000000000000"
}`
    },
    'get-price': {
      method: 'GET',
      path: '/api/v1/price/{token}',
      description: 'Get token price information',
      parameters: [
        { name: 'token', type: 'string', required: true, description: 'Token symbol or address' }
      ],
      response: `{
  "price": "0.015",
  "change24h": "5.67",
  "volume24h": "1234567.89"
}`
    }
  };

  const handleCopyCode = (code: string) => {
    navigator.clipboard.writeText(code);
    toast.success('Code copied to clipboard');
  };

  const handleGenerateKey = () => {
    setApiKey('zpt_' + Math.random().toString(36).substring(2, 15));
    toast.success('API key generated successfully');
  };

  return (
    <div className="pt-24 min-h-screen px-6">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
            API Reference
          </h1>
          <p className="text-white/70 max-w-2xl mx-auto">
            Complete API documentation for developers
          </p>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8">
          {/* Sidebar */}
          <div className="lg:col-span-1">
            <div className="glow-card p-6 rounded-2xl">
              <h3 className="text-xl font-semibold mb-4">Endpoints</h3>
              <div className="space-y-2">
                {Object.entries(endpoints).map(([key, endpoint]) => (
                  <button
                    key={key}
                    onClick={() => setSelectedEndpoint(key)}
                    className={`w-full text-left px-4 py-2 rounded-lg transition-colors ${
                      selectedEndpoint === key
                        ? 'bg-purple-500/20 text-purple-300'
                        : 'text-white/60 hover:text-white hover:bg-white/10'
                    }`}
                  >
                    <div className="flex items-center gap-2">
                      <span className={`text-xs ${
                        endpoint.method === 'GET' ? 'text-green-400' : 'text-blue-400'
                      }`}>
                        {endpoint.method}
                      </span>
                      <span className="truncate">{endpoint.path}</span>
                    </div>
                  </button>
                ))}
              </div>
            </div>

            {/* API Key Management */}
            <div className="glow-card p-6 rounded-2xl mt-6">
              <h3 className="text-xl font-semibold mb-4">API Key</h3>
              <div className="space-y-4">
                <input
                  type="text"
                  value={apiKey}
                  readOnly
                  className="w-full bg-black/20 border border-purple-500/20 rounded-lg px-4 py-2 text-white"
                  placeholder="No API key generated"
                />
                <button
                  onClick={handleGenerateKey}
                  className="w-full py-2 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg text-white font-semibold hover:opacity-90 transition-opacity"
                >
                  Generate New Key
                </button>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="lg:col-span-3">
            <div className="glow-card p-6 rounded-2xl">
              <div className="flex items-center justify-between mb-6">
                <h2 className="text-2xl font-semibold">
                  {endpoints[selectedEndpoint].path}
                </h2>
                <span className={`px-3 py-1 rounded-full text-sm ${
                  endpoints[selectedEndpoint].method === 'GET'
                    ? 'bg-green-500/20 text-green-300'
                    : 'bg-blue-500/20 text-blue-300'
                }`}>
                  {endpoints[selectedEndpoint].method}
                </span>
              </div>

              <div className="space-y-6">
                <div>
                  <h3 className="text-lg font-semibold mb-2">Description</h3>
                  <p className="text-white/70">
                    {endpoints[selectedEndpoint].description}
                  </p>
                </div>

                <div>
                  <h3 className="text-lg font-semibold mb-2">Parameters</h3>
                  <div className="bg-black/20 rounded-lg p-4">
                    <table className="w-full">
                      <thead>
                        <tr className="text-left text-white/60">
                          <th className="py-2">Name</th>
                          <th className="py-2">Type</th>
                          <th className="py-2">Required</th>
                          <th className="py-2">Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {endpoints[selectedEndpoint].parameters.map((param, index) => (
                          <tr key={index} className="border-t border-white/10">
                            <td className="py-2">{param.name}</td>
                            <td className="py-2 text-purple-300">{param.type}</td>
                            <td className="py-2">
                              {param.required ? (
                                <span className="text-green-400">Yes</span>
                              ) : (
                                <span className="text-white/40">No</span>
                              )}
                            </td>
                            <td className="py-2 text-white/70">{param.description}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div>
                  <h3 className="text-lg font-semibold mb-2">Example Response</h3>
                  <div className="relative">
                    <pre className="bg-black/20 rounded-lg p-4 overflow-x-auto">
                      <code className="text-white/90">
                        {endpoints[selectedEndpoint].response}
                      </code>
                    </pre>
                    <button
                      onClick={() => handleCopyCode(endpoints[selectedEndpoint].response)}
                      className="absolute top-2 right-2 p-2 rounded-lg bg-white/5 hover:bg-white/10 transition-colors"
                    >
                      <Copy size={16} />
                    </button>
                  </div>
                </div>

                <div>
                  <h3 className="text-lg font-semibold mb-2">Try It Out</h3>
                  <div className="bg-black/20 rounded-lg p-4">
                    <div className="space-y-4">
                      {endpoints[selectedEndpoint].parameters.map((param, index) => (
                        <div key={index}>
                          <label className="block text-sm text-white/70 mb-1">
                            {param.name}
                          </label>
                          <input
                            type="text"
                            placeholder={`Enter ${param.name}`}
                            className="w-full bg-black/40 border border-purple-500/20 rounded-lg px-4 py-2 text-white"
                          />
                        </div>
                      ))}
                      <button className="px-6 py-2 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg text-white font-semibold hover:opacity-90 transition-opacity">
                        Send Request
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}