import { motion } from 'framer-motion';
import { Terminal, Wrench, Bug, GitBranch, Code, Play, Database, Settings } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

export function DevToolsPage() {
  const [activeTab, setActiveTab] = useState('cli');
  const [cliCommand, setCliCommand] = useState('npx hardhat compile');

  const handleRunCommand = () => {
    toast.success('Command executed successfully');
  };

  return (
    <div className="pt-24 min-h-screen px-6">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
            Developer Tools
          </h1>
          <p className="text-white/70 max-w-2xl mx-auto">
            Professional tools for blockchain developers
          </p>
        </motion.div>

        {/* Tab Navigation */}
        <div className="flex flex-wrap justify-center gap-4 mb-8">
          {[
            { id: 'cli', label: 'CLI Tools', icon: Terminal },
            { id: 'debug', label: 'Debugger', icon: Bug },
            { id: 'sandbox', label: 'Sandbox', icon: Code }
          ].map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`px-6 py-3 rounded-xl transition-all duration-300 flex items-center gap-2 ${
                activeTab === tab.id
                  ? 'bg-purple-500/20 text-purple-300 shadow-lg shadow-purple-500/20'
                  : 'text-white/70 hover:text-white hover:bg-white/10'
              }`}
            >
              <tab.icon size={20} />
              {tab.label}
            </button>
          ))}
        </div>

        {/* CLI Tools */}
        {activeTab === 'cli' && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="glow-card p-6 rounded-2xl"
            >
              <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
                <Terminal className="w-5 h-5" />
                Command Line Interface
              </h3>
              <div className="space-y-4">
                <div className="bg-black/20 p-4 rounded-xl">
                  <input
                    type="text"
                    value={cliCommand}
                    onChange={(e) => setCliCommand(e.target.value)}
                    className="w-full bg-black/40 border border-purple-500/20 rounded-lg px-4 py-2 text-white font-mono"
                    placeholder="Enter command..."
                  />
                  <button
                    onClick={handleRunCommand}
                    className="mt-4 px-4 py-2 bg-purple-500/20 rounded-lg text-purple-300 hover:bg-purple-500/30 transition-colors"
                  >
                    Run Command
                  </button>
                </div>
                <div className="bg-black/20 p-4 rounded-xl h-64 overflow-auto font-mono text-sm">
                  <p className="text-green-400">$ {cliCommand}</p>
                  <p className="text-white/70">Compiling contracts...</p>
                  <p className="text-white/70">Successfully compiled 1 contract</p>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="space-y-8"
            >
              <div className="glow-card p-6 rounded-2xl">
                <h3 className="text-xl font-semibold mb-4">Quick Actions</h3>
                <div className="grid grid-cols-2 gap-4">
                  {[
                    { icon: Code, label: 'Compile' },
                    { icon: Play, label: 'Deploy' },
                    { icon: Database, label: 'Migrate' },
                    { icon: Settings, label: 'Configure' }
                  ].map((action, index) => (
                    <button
                      key={index}
                      className="p-4 bg-black/20 rounded-xl hover:bg-black/30 transition-colors flex flex-col items-center gap-2"
                    >
                      <action.icon className="w-6 h-6 text-purple-400" />
                      <span>{action.label}</span>
                    </button>
                  ))}
                </div>
              </div>

              <div className="glow-card p-6 rounded-2xl">
                <h3 className="text-xl font-semibold mb-4">Recent Commands</h3>
                <div className="space-y-2">
                  {[
                    'npx hardhat compile',
                    'npx hardhat test',
                    'npx hardhat run scripts/deploy.js',
                    'npx hardhat verify'
                  ].map((cmd, index) => (
                    <div
                      key={index}
                      className="p-3 bg-black/20 rounded-lg font-mono text-sm cursor-pointer hover:bg-black/30 transition-colors"
                      onClick={() => setCliCommand(cmd)}
                    >
                      {cmd}
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          </div>
        )}

        {/* Debugger */}
        {activeTab === 'debug' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="grid grid-cols-1 md:grid-cols-2 gap-8"
          >
            <div className="glow-card p-6 rounded-2xl">
              <h3 className="text-xl font-semibold mb-4">Transaction Debugger</h3>
              <div className="space-y-4">
                <input
                  type="text"
                  placeholder="Enter transaction hash..."
                  className="w-full bg-black/40 border border-purple-500/20 rounded-lg px-4 py-2 text-white"
                />
                <button className="w-full py-2 bg-purple-500/20 rounded-lg text-purple-300 hover:bg-purple-500/30 transition-colors">
                  Debug Transaction
                </button>
              </div>
            </div>

            <div className="glow-card p-6 rounded-2xl">
              <h3 className="text-xl font-semibold mb-4">Debug Console</h3>
              <div className="bg-black/20 p-4 rounded-xl h-64 overflow-auto font-mono text-sm">
                <p className="text-white/70">$ Loading debug information...</p>
              </div>
            </div>
          </motion.div>
        )}

        {/* Sandbox */}
        {activeTab === 'sandbox' && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="glow-card p-6 rounded-2xl"
          >
            <h3 className="text-xl font-semibold mb-4">Smart Contract Sandbox</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <textarea
                  className="w-full h-96 bg-black/20 p-4 rounded-xl font-mono text-sm text-white/90 resize-none"
                  placeholder="// Write your smart contract here..."
                />
              </div>
              <div className="space-y-4">
                <div className="bg-black/20 p-4 rounded-xl">
                  <h4 className="font-semibold mb-2">Compilation Output</h4>
                  <div className="h-44 overflow-auto font-mono text-sm">
                    <p className="text-green-400">Ready to compile...</p>
                  </div>
                </div>
                <div className="bg-black/20 p-4 rounded-xl">
                  <h4 className="font-semibold mb-2">Test Results</h4>
                  <div className="h-44 overflow-auto font-mono text-sm">
                    <p className="text-white/70">No tests run yet</p>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
}