import { motion } from 'framer-motion';
import { Upload, Image as ImageIcon, X } from 'lucide-react';
import { useState, useRef } from 'react';
import { toast } from 'react-hot-toast';

export function NFTCreate() {
  const [image, setImage] = useState<string | null>(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        toast.error('Image size should be less than 5MB');
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!image || !name || !description || !price) {
      toast.error('Please fill in all fields');
      return;
    }
    toast.success('NFT created successfully!');
    // Reset form
    setImage(null);
    setName('');
    setDescription('');
    setPrice('');
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="glow-card p-6 rounded-2xl"
    >
      <h3 className="text-2xl font-semibold mb-6">Create New NFT</h3>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm text-white/70 mb-2">Image</label>
          <div
            onClick={() => fileInputRef.current?.click()}
            className={`relative border-2 border-dashed rounded-xl transition-colors cursor-pointer ${
              image ? 'border-purple-500/50' : 'border-white/20 hover:border-white/40'
            }`}
          >
            <input
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              className="hidden"
            />
            {image ? (
              <div className="relative">
                <img
                  src={image}
                  alt="Preview"
                  className="w-full h-64 object-cover rounded-xl"
                />
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setImage(null);
                  }}
                  className="absolute top-2 right-2 p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
                >
                  <X size={16} />
                </button>
              </div>
            ) : (
              <div className="h-64 flex flex-col items-center justify-center">
                <ImageIcon className="w-12 h-12 text-white/40 mb-2" />
                <p className="text-white/60">Click to upload image</p>
                <p className="text-white/40 text-sm">Max size: 5MB</p>
              </div>
            )}
          </div>
        </div>

        <div>
          <label className="block text-sm text-white/70 mb-2">Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full bg-black/20 border border-purple-500/20 rounded-lg px-4 py-3 text-white focus:border-purple-500/50 transition-colors"
            placeholder="Enter NFT name"
          />
        </div>

        <div>
          <label className="block text-sm text-white/70 mb-2">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full h-32 bg-black/20 border border-purple-500/20 rounded-lg px-4 py-3 text-white focus:border-purple-500/50 transition-colors resize-none"
            placeholder="Describe your NFT"
          />
        </div>

        <div>
          <label className="block text-sm text-white/70 mb-2">Price (ETH)</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            step="0.001"
            min="0"
            className="w-full bg-black/20 border border-purple-500/20 rounded-lg px-4 py-3 text-white focus:border-purple-500/50 transition-colors"
            placeholder="Enter price in ETH"
          />
        </div>

        <button
          type="submit"
          className="w-full py-3 bg-gradient-to-r from-purple-600 to-pink-600 rounded-xl text-white font-semibold hover:opacity-90 transition-opacity"
        >
          Create NFT
        </button>
      </form>
    </motion.div>
  );
}