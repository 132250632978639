import { motion } from 'framer-motion';
import { Code2, BookOpen, Terminal, GitBranch, PlayCircle, FileText, Zap, Shield } from 'lucide-react';
import { useState } from 'react';

export function DocsPage() {
  const [activeTab, setActiveTab] = useState('guides');

  const tabs = [
    { id: 'guides', label: 'Guides', icon: BookOpen },
    { id: 'api', label: 'API Reference', icon: Code2 },
    { id: 'examples', label: 'Examples', icon: Terminal },
    { id: 'security', label: 'Security', icon: Shield }
  ];

  const content = {
    guides: [
      {
        title: 'Getting Started',
        description: 'Learn the basics of ZPecta platform',
        icon: PlayCircle,
        sections: ['Platform Overview', 'Account Setup', 'Basic Operations']
      },
      {
        title: 'Integration Guide',
        description: 'Integrate ZPecta into your applications',
        icon: Zap,
        sections: ['SDK Setup', 'Authentication', 'API Endpoints']
      },
      {
        title: 'Advanced Features',
        description: 'Explore advanced platform capabilities',
        icon: FileText,
        sections: ['Smart Contracts', 'Cross-chain Operations', 'Advanced Trading']
      }
    ],
    api: [
      {
        title: 'REST API',
        description: 'Complete REST API documentation',
        icon: Code2,
        sections: ['Authentication', 'Endpoints', 'Rate Limits']
      },
      {
        title: 'WebSocket API',
        description: 'Real-time data streaming',
        icon: Zap,
        sections: ['Connection', 'Subscriptions', 'Events']
      },
      {
        title: 'SDK Reference',
        description: 'Official SDK documentation',
        icon: FileText,
        sections: ['Installation', 'Configuration', 'Methods']
      }
    ],
    examples: [
      {
        title: 'Code Samples',
        description: 'Ready-to-use code examples',
        icon: Terminal,
        sections: ['Basic Integration', 'Trading Bot', 'Portfolio Tracker']
      },
      {
        title: 'Tutorials',
        description: 'Step-by-step implementation guides',
        icon: BookOpen,
        sections: ['Simple Swap', 'Liquidity Pool', 'NFT Minting']
      },
      {
        title: 'Best Practices',
        description: 'Recommended implementation patterns',
        icon: Shield,
        sections: ['Error Handling', 'Security', 'Performance']
      }
    ],
    security: [
      {
        title: 'Security Guidelines',
        description: 'Platform security best practices',
        icon: Shield,
        sections: ['Access Control', 'Data Protection', 'Audit Logs']
      },
      {
        title: 'Compliance',
        description: 'Regulatory compliance documentation',
        icon: FileText,
        sections: ['KYC/AML', 'Data Privacy', 'Reporting']
      },
      {
        title: 'Risk Management',
        description: 'Risk assessment and mitigation',
        icon: Shield,
        sections: ['Transaction Monitoring', 'Fraud Prevention', 'Emergency Response']
      }
    ]
  };

  return (
    <div className="pt-24 min-h-screen px-6">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-bold mb-4">Documentation</h1>
          <p className="text-white/70 max-w-2xl mx-auto">
            Comprehensive guides and documentation for developers
          </p>
        </motion.div>

        <div className="flex justify-center mb-12">
          <div className="flex gap-2 p-1 bg-white/5 rounded-xl">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-all ${
                  activeTab === tab.id
                    ? 'bg-white/10 text-white'
                    : 'text-white/60 hover:text-white'
                }`}
              >
                <tab.icon size={18} />
                {tab.label}
              </button>
            ))}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {content[activeTab].map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="glow-card rounded-2xl p-6"
            >
              <item.icon className="w-12 h-12 mb-4 text-purple-500" />
              <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
              <p className="text-white/70 mb-4">{item.description}</p>
              <ul className="space-y-2">
                {item.sections.map((section, sIndex) => (
                  <li
                    key={sIndex}
                    className="flex items-center gap-2 text-white/60 hover:text-white transition-colors cursor-pointer"
                  >
                    <div className="w-1.5 h-1.5 rounded-full bg-purple-500" />
                    {section}
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}