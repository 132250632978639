import { motion } from 'framer-motion';
import { Github, Twitter, Linkedin } from 'lucide-react';
import { Link } from 'react-router-dom';

export function Footer() {
  return (
    <footer className="bg-black/95 text-white py-16 mt-20">
      <div className="max-w-7xl mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
          <div>
            <h3 className="text-2xl font-bold mb-6">ZPecta</h3>
            <p className="text-white/70">
              Ihre vertrauenswürdige Web3-Plattform für dezentrale Anwendungen und Blockchain-Technologie.
            </p>
            <div className="flex gap-4 mt-6">
              {[Github, Twitter, Linkedin].map((Icon, index) => (
                <motion.a
                  key={index}
                  href="#"
                  whileHover={{ scale: 1.2, rotate: 5 }}
                  className="text-white/70 hover:text-white transition-colors"
                >
                  <Icon size={24} />
                </motion.a>
              ))}
            </div>
          </div>

          <div>
            <h4 className="text-xl font-semibold mb-6">Web3 Services</h4>
            <ul className="space-y-3">
              <li>
                <Link to="/defi">
                  <motion.span
                    whileHover={{ x: 5 }}
                    className="text-white/70 hover:text-white transition-colors block"
                  >
                    DeFi Integration
                  </motion.span>
                </Link>
              </li>
              <li>
                <Link to="/nft">
                  <motion.span
                    whileHover={{ x: 5 }}
                    className="text-white/70 hover:text-white transition-colors block"
                  >
                    NFT Marketplace
                  </motion.span>
                </Link>
              </li>
              <li>
                <Link to="/">
                  <motion.span
                    whileHover={{ x: 5 }}
                    className="text-white/70 hover:text-white transition-colors block"
                  >
                    Token Swaps
                  </motion.span>
                </Link>
              </li>
              <li>
                <Link to="/smart-contracts">
                  <motion.span
                    whileHover={{ x: 5 }}
                    className="text-white/70 hover:text-white transition-colors block"
                  >
                    Smart Contracts
                  </motion.span>
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-xl font-semibold mb-6">Ressourcen</h4>
            <ul className="space-y-3">
              <li>
                <Link to="/docs">
                  <motion.span
                    whileHover={{ x: 5 }}
                    className="text-white/70 hover:text-white transition-colors block"
                  >
                    Dokumentation
                  </motion.span>
                </Link>
              </li>
              <li>
                <Link to="/api">
                  <motion.span
                    whileHover={{ x: 5 }}
                    className="text-white/70 hover:text-white transition-colors block"
                  >
                    API Referenz
                  </motion.span>
                </Link>
              </li>
              <li>
                <Link to="/dev-tools">
                  <motion.span
                    whileHover={{ x: 5 }}
                    className="text-white/70 hover:text-white transition-colors block"
                  >
                    Entwickler Tools
                  </motion.span>
                </Link>
              </li>
              <li>
                <Link to="/explorer">
                  <motion.span
                    whileHover={{ x: 5 }}
                    className="text-white/70 hover:text-white transition-colors block"
                  >
                    Blockchain Explorer
                  </motion.span>
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h4 className="text-xl font-semibold mb-6">Support</h4>
            <ul className="space-y-3">
              <li>
                <Link to="/contact">
                  <motion.span
                    whileHover={{ x: 5 }}
                    className="text-white/70 hover:text-white transition-colors block"
                  >
                    Contact Us
                  </motion.span>
                </Link>
              </li>
              <li>
                <Link to="/grants">
                  <motion.span
                    whileHover={{ x: 5 }}
                    className="text-white/70 hover:text-white transition-colors block"
                  >
                    Small Grants
                  </motion.span>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="border-t border-white/10 mt-12 pt-8 text-center text-white/70">
          <p>© {new Date().getFullYear()} ZPecta. Alle Rechte vorbehalten.</p>
        </div>
      </div>
    </footer>
  );
}