import { motion } from 'framer-motion';
import { Coins, FileText, Mail, Send, User } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

export function GrantsPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    projectName: '',
    fundingAmount: '',
    description: '',
    timeline: '',
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          access_key: '9af05b82-4ad3-4d3c-9e2d-f9517ee9f33c',
          from_name: formData.name,
          subject: `Grant Application: ${formData.projectName}`,
          email: formData.email,
          message: `
            Project Name: ${formData.projectName}
            Funding Amount: ${formData.fundingAmount}
            Description: ${formData.description}
            Timeline: ${formData.timeline}
          `,
          to: 'office@innopz-zpectum.tech',
        }),
      });

      if (response.ok) {
        toast.success('Application submitted successfully!');
        setFormData({
          name: '',
          email: '',
          projectName: '',
          fundingAmount: '',
          description: '',
          timeline: '',
        });
      } else {
        throw new Error('Failed to submit application');
      }
    } catch (error) {
      toast.error('Failed to submit application. Please try again.');
    }
  };

  return (
    <div className="pt-24 min-h-screen px-6">
      <div className="max-w-4xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
            Small Grants Program
          </h1>
          <p className="text-white/70 max-w-2xl mx-auto">
            Apply for funding to support your innovative blockchain project
          </p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="glow-card p-8 rounded-2xl"
        >
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm text-white/70 mb-2">Name</label>
                <div className="relative">
                  <User
                    className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white/40"
                    size={18}
                  />
                  <input
                    type="text"
                    required
                    value={formData.name}
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value })
                    }
                    className="w-full bg-black/20 border border-purple-500/20 rounded-xl pl-12 pr-4 py-3 text-white placeholder-white/40 focus:border-purple-500/50 transition-colors"
                    placeholder="Your name"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm text-white/70 mb-2">
                  Email
                </label>
                <div className="relative">
                  <Mail
                    className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white/40"
                    size={18}
                  />
                  <input
                    type="email"
                    required
                    value={formData.email}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                    className="w-full bg-black/20 border border-purple-500/20 rounded-xl pl-12 pr-4 py-3 text-white placeholder-white/40 focus:border-purple-500/50 transition-colors"
                    placeholder="your@email.com"
                  />
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm text-white/70 mb-2">
                Project Name
              </label>
              <div className="relative">
                <FileText
                  className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white/40"
                  size={18}
                />
                <input
                  type="text"
                  required
                  value={formData.projectName}
                  onChange={(e) =>
                    setFormData({ ...formData, projectName: e.target.value })
                  }
                  className="w-full bg-black/20 border border-purple-500/20 rounded-xl pl-12 pr-4 py-3 text-white placeholder-white/40 focus:border-purple-500/50 transition-colors"
                  placeholder="Your project name"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm text-white/70 mb-2">
                Funding Amount (USD)
              </label>
              <div className="relative">
                <Coins
                  className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white/40"
                  size={18}
                />
                <input
                  type="number"
                  required
                  value={formData.fundingAmount}
                  onChange={(e) =>
                    setFormData({ ...formData, fundingAmount: e.target.value })
                  }
                  className="w-full bg-black/20 border border-purple-500/20 rounded-xl pl-12 pr-4 py-3 text-white placeholder-white/40 focus:border-purple-500/50 transition-colors"
                  placeholder="Requested amount in USD"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm text-white/70 mb-2">
                Project Description
              </label>
              <textarea
                required
                value={formData.description}
                onChange={(e) =>
                  setFormData({ ...formData, description: e.target.value })
                }
                className="w-full h-40 bg-black/20 border border-purple-500/20 rounded-xl px-4 py-3 text-white placeholder-white/40 focus:border-purple-500/50 transition-colors resize-none"
                placeholder="Describe your project, its goals, and how you plan to use the funding..."
              />
            </div>

            <div>
              <label className="block text-sm text-white/70 mb-2">
                Project Timeline
              </label>
              <textarea
                required
                value={formData.timeline}
                onChange={(e) =>
                  setFormData({ ...formData, timeline: e.target.value })
                }
                className="w-full h-32 bg-black/20 border border-purple-500/20 rounded-xl px-4 py-3 text-white placeholder-white/40 focus:border-purple-500/50 transition-colors resize-none"
                placeholder="Outline your project timeline and major milestones..."
              />
            </div>

            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              type="submit"
              className="w-full py-3 bg-gradient-to-r from-purple-600 to-pink-600 rounded-xl text-white font-semibold hover:opacity-90 transition-opacity flex items-center justify-center gap-2"
            >
              <Send size={18} />
              Submit Application
            </motion.button>
          </form>
        </motion.div>
      </div>
    </div>
  );
}
