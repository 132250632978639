import { motion } from 'framer-motion';
import { Wallet, Shield, Zap, BarChart3, Lock, Globe, Layers, Cpu } from 'lucide-react';

const features = [
  {
    icon: Wallet,
    title: 'Multi-Chain Integration',
    description: 'Unterstützung für Ethereum, Polygon, BSC und mehr'
  },
  {
    icon: Shield,
    title: 'Maximale Sicherheit',
    description: 'Fortschrittliche Verschlüsselung und Audits'
  },
  {
    icon: Zap,
    title: 'Blitzschnelle Trades',
    description: 'Optimierte Transaktionen mit minimaler Latenz'
  },
  {
    icon: BarChart3,
    title: 'Analytics Suite',
    description: 'Umfassende Marktanalysen und Trends'
  },
  {
    icon: Lock,
    title: 'Smart Contract Sicherheit',
    description: 'Automatisierte Audits und Überwachung'
  },
  {
    icon: Globe,
    title: 'Globales Netzwerk',
    description: 'Dezentralisierte Infrastruktur weltweit'
  },
  {
    icon: Layers,
    title: 'Layer 2 Support',
    description: 'Skalierbare Lösungen für niedrige Gebühren'
  },
  {
    icon: Cpu,
    title: 'KI-Integration',
    description: 'Intelligente Handelsstrategien und Analysen'
  }
];

export function Features() {
  return (
    <div className="min-h-screen py-20 px-6">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-4">Platform Features</h2>
          <p className="text-white/70 max-w-2xl mx-auto">
            Entdecken Sie die fortschrittlichen Funktionen unserer Web3-Plattform
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              whileHover={{ scale: 1.05 }}
              className="bg-white/5 backdrop-blur-md p-6 rounded-2xl border border-white/10 group"
            >
              <feature.icon className="w-12 h-12 mb-4 text-white/60 group-hover:text-white transition-colors" />
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-white/60 group-hover:text-white/80 transition-colors">{feature.description}</p>
            </motion.div>
          ))}
        </div>

        {/* Advanced Features Section */}
        <div className="mt-20">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold mb-4">Advanced Trading</h2>
            <p className="text-white/70 max-w-2xl mx-auto">
              Professionelle Trading-Tools und Funktionen
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="bg-white/5 backdrop-blur-md p-8 rounded-2xl border border-white/10"
            >
              <h3 className="text-2xl font-semibold mb-6">Trading Features</h3>
              <ul className="space-y-4">
                <li className="flex items-center gap-3">
                  <div className="w-2 h-2 rounded-full bg-white/60" />
                  <span className="text-white/80">Limit Orders</span>
                </li>
                <li className="flex items-center gap-3">
                  <div className="w-2 h-2 rounded-full bg-white/60" />
                  <span className="text-white/80">Stop-Loss Orders</span>
                </li>
                <li className="flex items-center gap-3">
                  <div className="w-2 h-2 rounded-full bg-white/60" />
                  <span className="text-white/80">Dollar Cost Averaging</span>
                </li>
                <li className="flex items-center gap-3">
                  <div className="w-2 h-2 rounded-full bg-white/60" />
                  <span className="text-white/80">Margin Trading</span>
                </li>
              </ul>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="bg-white/5 backdrop-blur-md p-8 rounded-2xl border border-white/10"
            >
              <h3 className="text-2xl font-semibold mb-6">Analytics Tools</h3>
              <ul className="space-y-4">
                <li className="flex items-center gap-3">
                  <div className="w-2 h-2 rounded-full bg-white/60" />
                  <span className="text-white/80">TradingView Charts</span>
                </li>
                <li className="flex items-center gap-3">
                  <div className="w-2 h-2 rounded-full bg-white/60" />
                  <span className="text-white/80">Technical Indicators</span>
                </li>
                <li className="flex items-center gap-3">
                  <div className="w-2 h-2 rounded-full bg-white/60" />
                  <span className="text-white/80">Market Depth</span>
                </li>
                <li className="flex items-center gap-3">
                  <div className="w-2 h-2 rounded-full bg-white/60" />
                  <span className="text-white/80">Order Book Analysis</span>
                </li>
              </ul>
            </motion.div>
          </div>
        </div>

        {/* Security Section */}
        <div className="mt-20">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold mb-4">Security First</h2>
            <p className="text-white/70 max-w-2xl mx-auto">
              Ihre Sicherheit hat höchste Priorität
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {[
              {
                title: "Wallet Security",
                features: ["Multi-Sig Support", "Hardware Wallet Integration", "Address Whitelisting"]
              },
              {
                title: "Platform Security",
                features: ["2FA Authentication", "IP Whitelisting", "Anti-Phishing Protection"]
              },
              {
                title: "Smart Contract Security",
                features: ["Regular Audits", "Bug Bounty Program", "Insurance Coverage"]
              }
            ].map((section, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white/5 backdrop-blur-md p-6 rounded-2xl border border-white/10"
              >
                <h3 className="text-xl font-semibold mb-4">{section.title}</h3>
                <ul className="space-y-3">
                  {section.features.map((feature, fIndex) => (
                    <li key={fIndex} className="flex items-center gap-3">
                      <div className="w-2 h-2 rounded-full bg-white/60" />
                      <span className="text-white/80">{feature}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}