import { motion } from 'framer-motion';
import { TokenSwap } from '../components/TokenSwap';
import { Link } from 'react-router-dom';
import { PriceChart } from '../components/PriceChart';
import { VolumeChart } from '../components/VolumeChart';
import { useChartData } from '../hooks/useChartData';

export function SwapPage() {
  const { priceData, volumeData } = useChartData();

  const handleStartTrading = () => {
    const swapSection = document.getElementById('swap-section');
    if (swapSection) {
      swapSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleLearnMore = () => {
    const analysisSection = document.getElementById('analysis-section');
    if (analysisSection) {
      analysisSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <div className="relative h-screen flex items-center justify-center bg-gradient-to-b from-purple-900/0 via-transparent to-transparent">
        <div className="relative z-10 text-center px-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-4xl mx-auto"
          >
            <h1 className="text-5xl md:text-7xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400">
              Web3
            </h1>
            <h1 className="text-5xl md:text-7xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400">
              ZPecta Token
            </h1>
            <p className="text-xl text-white/70 mb-8">
              The future of decentralized finance
            </p>

            <div className="flex flex-wrap justify-center gap-4">
              <motion.button
                onClick={handleStartTrading}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-3 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full text-white font-semibold hover:shadow-lg hover:shadow-purple-500/25 transition-all duration-300"
              >
                Start Trading
              </motion.button>
              <motion.button
                onClick={handleLearnMore}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-3 border border-purple-500/50 rounded-full text-white font-semibold hover:bg-purple-500/20 transition-all duration-300"
              >
                Learn More
              </motion.button>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Swap Interface */}
      <div
        id="swap-section"
        className="py-20 px-6 bg-gradient-to-b from-purple-900/0 via-transparent to-transparent"
      >
        <div className="max-w-7xl mx-auto">
          <TokenSwap />
        </div>
      </div>

      {/* Live Charts */}
      <div className="py-20 px-6 bg-gradient-to-b from-purple-900/5 via-transparent to-transparent">
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400">
              Live Market Data
            </h2>
            <p className="text-white/70 max-w-2xl mx-auto">
              Real-time price and volume analysis
            </p>
          </motion.div>

          <div className="grid grid-cols-1 gap-8">
            <div className="glow-card p-6 rounded-2xl">
              <h3 className="text-xl font-semibold mb-4">Price Chart</h3>
              <PriceChart data={priceData} />
            </div>
            <div className="glow-card p-6 rounded-2xl">
              <h3 className="text-xl font-semibold mb-4">Volume Analysis</h3>
              <VolumeChart data={volumeData} />
            </div>
          </div>
        </div>
      </div>

      {/* Market Analysis */}
      <div
        id="analysis-section"
        className="py-20 px-6 bg-gradient-to-b from-purple-900/5 via-transparent to-transparent"
      >
        <div className="max-w-7xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-pink-400">
              Market Analysis
            </h2>
            <p className="text-white/70 max-w-2xl mx-auto">
              Comprehensive market insights and trends
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="glow-card p-8 rounded-2xl"
            >
              <h3 className="text-2xl font-semibold mb-6">Market Overview</h3>
              <div className="space-y-4">
                <div className="flex justify-between">
                  <span className="text-white/70">24h Volume</span>
                  <span className="font-semibold">$2.5M</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-white/70">Market Cap</span>
                  <span className="font-semibold">$15M</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-white/70">Total Holders</span>
                  <span className="font-semibold">12,345</span>
                </div>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="glow-card p-8 rounded-2xl"
            >
              <h3 className="text-2xl font-semibold mb-6">Price Statistics</h3>
              <div className="space-y-4">
                <div className="flex justify-between">
                  <span className="text-white/70">Current Price</span>
                  <span className="font-semibold">$0.015</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-white/70">24h Change</span>
                  <span className="font-semibold text-green-400">+5.67%</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-white/70">7d Change</span>
                  <span className="font-semibold text-green-400">+12.34%</span>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}
