import { Canvas } from '@react-three/fiber';
import { OrbitControls, Stars } from '@react-three/drei';
import { useRef } from 'react';
import * as THREE from 'three';

function SpaceBackground() {
  const groupRef = useRef();

  return (
    <group ref={groupRef}>
      <Stars
        radius={300}
        depth={50}
        count={5000}
        factor={4}
        saturation={0}
        fade
      />
      <ambientLight intensity={0.2} />
      <pointLight position={[10, 10, 10]} intensity={0.5} />
      <pointLight
        position={[-10, -10, -10]}
        color={new THREE.Color("#4c0099")}
        intensity={1}
      />
    </group>
  );
}

export function Scene() {
  return (
    <Canvas
      camera={{ position: [0, 0, 10], fov: 75 }}
      className="h-screen w-screen"
    >
      <OrbitControls 
        enableZoom={false} 
        autoRotate 
        autoRotateSpeed={0.5}
        enablePan={false}
        maxPolarAngle={Math.PI / 2}
        minPolarAngle={Math.PI / 2}
      />
      <SpaceBackground />
    </Canvas>
  );
}