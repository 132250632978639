import { Web3Provider } from './components/Web3Provider';
import { Navbar } from './components/Navbar';
import { Scene } from './components/Scene';
import { Footer } from './components/Footer';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { SwapPage } from './pages/SwapPage';
import { FeaturesPage } from './pages/FeaturesPage';
import { DocsPage } from './pages/DocsPage';
import { StatsPage } from './pages/StatsPage';
import { DeFiPage } from './pages/DeFiPage';
import { NFTPage } from './pages/NFTPage';
import { SmartContractsPage } from './pages/SmartContractsPage';
import { APIPage } from './pages/APIPage';
import { DevToolsPage } from './pages/DevToolsPage';
import { ExplorerPage } from './pages/ExplorerPage';
import { ContactPage } from './pages/ContactPage';
import { GrantsPage } from './pages/GrantsPage';
import { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './styles/effects.css';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export function App() {
  return (
    <Web3Provider>
      <BrowserRouter>
        <ScrollToTop />
        <div className="min-h-screen bg-black text-white relative">
          {/* Background Scene */}
          <div className="fixed inset-0 z-0">
            <Scene />
          </div>
          
          {/* Content */}
          <div className="relative z-10">
            <Navbar />
            
            <Routes>
              <Route path="/" element={<SwapPage />} />
              <Route path="/features" element={<FeaturesPage />} />
              <Route path="/docs" element={<DocsPage />} />
              <Route path="/stats" element={<StatsPage />} />
              <Route path="/defi" element={<DeFiPage />} />
              <Route path="/nft" element={<NFTPage />} />
              <Route path="/smart-contracts" element={<SmartContractsPage />} />
              <Route path="/api" element={<APIPage />} />
              <Route path="/dev-tools" element={<DevToolsPage />} />
              <Route path="/explorer" element={<ExplorerPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/grants" element={<GrantsPage />} />
            </Routes>

            <Footer />
          </div>
        </div>
        <Toaster
          position="top-right"
          toastOptions={{
            style: {
              background: '#1a1a1a',
              color: '#fff',
              border: '1px solid rgba(255, 255, 255, 0.1)'
            }
          }}
        />
      </BrowserRouter>
    </Web3Provider>
  );
}