import { motion } from 'framer-motion';
import { Heart } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

interface NFTCardProps {
  nft: {
    id: string;
    name: string;
    image: string;
    price: string;
    creator: string;
    likes: number;
  };
  viewMode: 'grid' | 'list';
}

export function NFTCard({ nft, viewMode }: NFTCardProps) {
  const [isLiked, setIsLiked] = useState(false);
  const [likes, setLikes] = useState(nft.likes);

  const handleLike = () => {
    if (!isLiked) {
      setLikes(prev => prev + 1);
      setIsLiked(true);
      toast.success('Added to favorites!');
    } else {
      setLikes(prev => prev - 1);
      setIsLiked(false);
      toast.success('Removed from favorites');
    }
  };

  const handleBuy = () => {
    toast.success('Purchase initiated! Connect your wallet to continue.');
  };

  if (viewMode === 'list') {
    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="glow-card p-4 rounded-2xl flex gap-6 items-center"
      >
        <img
          src={nft.image}
          alt={nft.name}
          className="w-24 h-24 object-cover rounded-xl"
        />
        <div className="flex-1">
          <h3 className="font-semibold text-lg">{nft.name}</h3>
          <p className="text-white/60 text-sm">By {nft.creator}</p>
        </div>
        <div className="text-right">
          <p className="text-sm text-white/60">Current Price</p>
          <p className="font-semibold">{nft.price}</p>
          <button
            onClick={handleBuy}
            className="mt-2 px-4 py-2 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg text-white hover:opacity-90 transition-opacity"
          >
            Buy Now
          </button>
        </div>
      </motion.div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="glow-card rounded-2xl overflow-hidden group"
    >
      <div className="relative">
        <img
          src={nft.image}
          alt={nft.name}
          className="w-full h-64 object-cover"
        />
        <button
          onClick={handleLike}
          className={`absolute top-4 right-4 p-2 rounded-full ${
            isLiked ? 'bg-pink-500' : 'bg-black/50'
          } backdrop-blur-sm transition-colors`}
        >
          <Heart
            size={20}
            className={isLiked ? 'text-white fill-current' : 'text-white'}
          />
        </button>
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity">
          <div className="absolute bottom-4 left-4 right-4">
            <p className="text-white font-semibold">{nft.name}</p>
            <p className="text-white/60 text-sm">By {nft.creator}</p>
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="flex justify-between items-center">
          <div>
            <p className="text-sm text-white/60">Current Price</p>
            <p className="text-lg font-semibold">{nft.price}</p>
          </div>
          <button
            onClick={handleBuy}
            className="px-4 py-2 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg text-white hover:opacity-90 transition-opacity"
          >
            Buy Now
          </button>
        </div>
        <div className="mt-2 flex items-center gap-2 text-white/60 text-sm">
          <Heart size={16} />
          <span>{likes} likes</span>
        </div>
      </div>
    </motion.div>
  );
}