import { motion } from 'framer-motion';
import { Wallet, ArrowRightLeft, LineChart, Lock, Coins, BarChart2, PieChart, TrendingUp } from 'lucide-react';
import { useState } from 'react';
import { PriceChart } from '../components/PriceChart';
import { useChartData } from '../hooks/useChartData';

export function DeFiPage() {
  const [activeTab, setActiveTab] = useState('pools');
  const { priceData } = useChartData();

  const tabs = [
    { id: 'pools', label: 'Liquidity Pools' },
    { id: 'farm', label: 'Yield Farming' },
    { id: 'stake', label: 'Staking' },
    { id: 'analytics', label: 'Analytics' }
  ];

  return (
    <div className="pt-24 min-h-screen px-6">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
            DeFi Integration
          </h1>
          <p className="text-white/70 max-w-2xl mx-auto">
            Advanced DeFi tools and analytics
          </p>
        </motion.div>

        {/* Tab Navigation */}
        <div className="flex flex-wrap justify-center gap-4 mb-12">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              className={`px-6 py-3 rounded-xl transition-all duration-300 ${
                activeTab === tab.id
                  ? 'bg-purple-500/20 text-purple-300 shadow-lg shadow-purple-500/20'
                  : 'text-white/70 hover:text-white hover:bg-white/10'
              }`}
            >
              {tab.label}
            </button>
          ))}
        </div>

        {/* Liquidity Pools */}
        {activeTab === 'pools' && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="glow-card p-6 rounded-2xl"
            >
              <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
                <Coins className="w-5 h-5" />
                Available Pools
              </h3>
              <div className="space-y-4">
                {[
                  { pair: 'ETH/ZPT', tvl: '$1.2M', apy: '12.5%' },
                  { pair: 'USDC/ZPT', tvl: '$800K', apy: '8.2%' },
                  { pair: 'BTC/ZPT', tvl: '$2.1M', apy: '15.7%' }
                ].map((pool, index) => (
                  <div key={index} className="flex justify-between items-center p-4 bg-black/20 rounded-xl">
                    <div>
                      <p className="font-semibold">{pool.pair}</p>
                      <p className="text-sm text-white/60">TVL: {pool.tvl}</p>
                    </div>
                    <div className="text-right">
                      <p className="text-green-400">{pool.apy} APY</p>
                      <button className="text-sm text-purple-400 hover:text-purple-300">
                        Add Liquidity
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              className="glow-card p-6 rounded-2xl"
            >
              <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
                <BarChart2 className="w-5 h-5" />
                Pool Performance
              </h3>
              <div className="h-[300px]">
                <PriceChart data={priceData} />
              </div>
            </motion.div>
          </div>
        )}

        {/* Yield Farming */}
        {activeTab === 'farm' && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="glow-card p-6 rounded-2xl"
            >
              <h3 className="text-xl font-semibold mb-4">Active Farms</h3>
              <div className="space-y-4">
                {[
                  { name: 'ZPT Farm', reward: '100 ZPT/day', tvl: '$500K', apy: '45%' },
                  { name: 'LP Farm', reward: '200 ZPT/day', tvl: '$1.2M', apy: '65%' }
                ].map((farm, index) => (
                  <div key={index} className="p-4 bg-black/20 rounded-xl">
                    <div className="flex justify-between mb-2">
                      <span className="font-semibold">{farm.name}</span>
                      <span className="text-green-400">{farm.apy} APY</span>
                    </div>
                    <div className="flex justify-between text-sm text-white/60">
                      <span>Reward: {farm.reward}</span>
                      <span>TVL: {farm.tvl}</span>
                    </div>
                    <button className="mt-2 w-full py-2 bg-purple-500/20 rounded-lg text-purple-300 hover:bg-purple-500/30 transition-colors">
                      Stake
                    </button>
                  </div>
                ))}
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="glow-card p-6 rounded-2xl"
            >
              <h3 className="text-xl font-semibold mb-4">Your Farming Stats</h3>
              <div className="grid grid-cols-2 gap-4 mb-6">
                <div className="p-4 bg-black/20 rounded-xl">
                  <p className="text-white/60 text-sm">Total Value Locked</p>
                  <p className="text-xl font-semibold">$2,345.67</p>
                </div>
                <div className="p-4 bg-black/20 rounded-xl">
                  <p className="text-white/60 text-sm">Daily Rewards</p>
                  <p className="text-xl font-semibold">45.6 ZPT</p>
                </div>
              </div>
              <div className="h-[200px] mb-4">
                <PriceChart data={priceData} />
              </div>
            </motion.div>
          </div>
        )}

        {/* Staking */}
        {activeTab === 'stake' && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="glow-card p-6 rounded-2xl"
            >
              <h3 className="text-xl font-semibold mb-4">Staking Options</h3>
              <div className="space-y-4">
                {[
                  { duration: '30 Days', apy: '15%', min: '100 ZPT' },
                  { duration: '90 Days', apy: '25%', min: '500 ZPT' },
                  { duration: '180 Days', apy: '40%', min: '1000 ZPT' }
                ].map((option, index) => (
                  <div key={index} className="p-4 bg-black/20 rounded-xl">
                    <div className="flex justify-between mb-2">
                      <span className="font-semibold">{option.duration}</span>
                      <span className="text-green-400">{option.apy} APY</span>
                    </div>
                    <p className="text-sm text-white/60">Min. Stake: {option.min}</p>
                    <button className="mt-2 w-full py-2 bg-purple-500/20 rounded-lg text-purple-300 hover:bg-purple-500/30 transition-colors">
                      Stake Now
                    </button>
                  </div>
                ))}
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="glow-card p-6 rounded-2xl"
            >
              <h3 className="text-xl font-semibold mb-4">Your Stakes</h3>
              <div className="space-y-4">
                <div className="p-4 bg-black/20 rounded-xl">
                  <div className="flex justify-between mb-2">
                    <span className="font-semibold">Active Stake</span>
                    <span className="text-green-400">1000 ZPT</span>
                  </div>
                  <div className="flex justify-between text-sm text-white/60">
                    <span>Locked until: 30 days</span>
                    <span>Earned: 25 ZPT</span>
                  </div>
                  <div className="mt-2 h-2 bg-black/40 rounded-full overflow-hidden">
                    <div className="h-full w-1/3 bg-gradient-to-r from-purple-500 to-pink-500" />
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        )}

        {/* Analytics */}
        {activeTab === 'analytics' && (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="glow-card p-6 rounded-2xl md:col-span-2"
            >
              <h3 className="text-xl font-semibold mb-4">Protocol Overview</h3>
              <div className="h-[300px]">
                <PriceChart data={priceData} />
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="glow-card p-6 rounded-2xl"
            >
              <h3 className="text-xl font-semibold mb-4">Key Metrics</h3>
              <div className="space-y-4">
                <div className="p-4 bg-black/20 rounded-xl">
                  <p className="text-white/60 text-sm">Total Value Locked</p>
                  <p className="text-xl font-semibold">$4.5M</p>
                </div>
                <div className="p-4 bg-black/20 rounded-xl">
                  <p className="text-white/60 text-sm">24h Volume</p>
                  <p className="text-xl font-semibold">$1.2M</p>
                </div>
                <div className="p-4 bg-black/20 rounded-xl">
                  <p className="text-white/60 text-sm">Active Users</p>
                  <p className="text-xl font-semibold">1,234</p>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </div>
    </div>
  );
}